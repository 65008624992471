.testinho {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: -1; /* Send to back */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: add a semi-transparent background */
    background-size: cover; /* Ensures the image covers the area without distortion */
    background-position: center; /* Centers the image */
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .background-video::-webkit-media-controls {
    display: none !important;
  }
  
  .background-video::-moz-media-controls {
    display: none !important;
  }
  
  .background-video::-ms-media-controls {
    display: none !important;
  }
  
  .background-video::-o-media-controls {
    display: none !important;
  }
  
  .background-video::media-controls {
    display: none !important;
  }

  body, html {
    overflow-x: hidden;
}
